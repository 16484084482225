@charset "UTF-8";

@import "../../css/setting";
@import "../../css/extends";
@import "../../module/index";

@charset "utf-8";
/* 汎用 */

a {
	color:#000000;
	text-decoration:underline;
}
a:hover img {
	opacity: 0.8;
	filter: alpha(opacity=80);
	-moz-opacity: 0.8;
}
.tal{text-align:left;}
.tac{text-align:center;}
.tar{text-align:right;}

.fl{float:left;}
.fr{float:right;}

.gothic {
	font-family: YuGothic, Yu Gothic, "游ゴシック", sans-serif;
}

.circleTitle {
	box-sizing: border-box;
	position: relative;
	margin: 0 auto 40px !important;
	width: 200px;
	height: 200px;
	border-radius: 100px;
	border: 18px solid #dcdddd;
	text-align: center;
}
.circleTitle span {
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	line-height: 1.4;
	font-size: 20px !important;
	font-weight: bold !important;
	-webkit-transform: translateY(-45%);
	-ms-transform: translateY(-45%);
	-moz-transform: translateY(-45%);
	transform: translateY(-45%);
}

/* margin
--------------------------*/
.mb10 {margin-bottom:10px;}
.mb15 {margin-bottom:15px;}
.mb20 {margin-bottom:20px;}
.mb25 {margin-bottom:25px;}
.mb30 {margin-bottom:30px;}
.mb35 {margin-bottom:35px;}
.mb40 {margin-bottom:40px;}
.mb45 {margin-bottom:45px;}
.mb50 {margin-bottom:50px;}
.mb55 {margin-bottom:55px;}
.mb60 {margin-bottom:60px;}
/* margin
--------------------------*/
.mt10 {margin-top:10px;}
.mt15 {margin-top:15px;}
.mt20 {margin-top:20px;}
.mt25 {margin-top:25px;}
.mt30 {margin-top:30px;}
.mt35 {margin-top:35px;}
.mt40 {margin-top:40px;}
.mt45 {margin-top:45px;}
.mt50 {margin-top:50px;}
.mt55 {margin-top:55px;}
.mt60 {margin-top:60px;}

/* font
--------------------------*/
.fs10{font-size:77%;}
.fs11{font-size:85%;}
.fs12{font-size:93%;}
.fs13{font-size:100%;}
.fs14{font-size:108%;}
.fs15{font-size:116%;}
.fs16{font-size:124%;}
.fs17{font-size:131%;}
.fs18{font-size:139%;}
.fs19{font-size:147%;}
.fs20{font-size:154%;}
.red{color:#a70100;}
.st{font-weight:bold;}

/* ボタンテキスト化
-----------------------------------------------------------------*/
.txtBtn_gray01{ /* グレーボタン（SEO関連リンク用）　サイズS */

}
.txtBtn_gray01 a{ /* グレーボタン（SEO関連リンク用）　サイズS */
	display:block;
	text-align:left;
	position: relative;
	background-color:#bbb;
	color:#fff;
	text-decoration:none;
	padding: 5px 20px 5px 10px;
	font-size:11px;
	position: relative;
	margin-bottom: 4px;
}
.txtBtn_gray01 a:before {
	content: "";
	width: 5px;
	height: 5px;
	border: solid #fff;
	border-width: 0 1px 1px 0;
	position: absolute;
	top: 10px;
	right: 10px;
	transform: rotate(-45deg);
}

/* 00 top
--------------------------*/
#keyVisual {
	margin: 0 auto;
	padding: 0;
	width: 100%;
	height:auto;
	text-align:center;
}
#keyVisual #slider {
	width:100%;
	height:auto;
}
#keyVisual #slider img {
	width: 100%;
	height:auto;
}
#keyVisual #slider .bx-box {
	width:100%;
	height:auto;
	position:relative;
}
#keyVisual #slider .bx-box .dammy {
	width:100%;
}
#keyVisual #slider .bx-box .img_triangle {
	width:76.562%;
	position:absolute;
	left:0;
	top:0;
}
#keyVisual #slider .bx-box .img_hash_top {
	width:44.218%;
	position:absolute;
	right:0.625%;
	top:28.5%;/*top:47px;*/
}
#keyVisual #slider .bx-box .img_hash {
	width:30.468%;
	position:absolute;
	left:44.218%;
	top:41.5%;
}
#keyVisual #slider .bx-box .img_four {
	/*width:71.718%;*/
	width:100%;
	position:absolute;
	/*right:0;
	top:40%;top:157px;*/
	left:0;
	top:0;
}
#keyVisual #slider .bx-box .img_27 {
	width:84.281%;
	position:absolute;
	right:2.218%;
	top:76%;/*top:486px;*/
}
#keyVisual #slider .bx-box .img_chara {
	width:100%;
	position:absolute;
	left:0;
	top:0;
}
#keyVisual .keyCv {
	background-color: #f5f5f5;

}
#keyVisual .keyCv h2 {
	height: auto;
	margin: 0 auto;

}
#keyVisual .keyCv ul{
	display:-webkit-box;/*--- Androidブラウザ用 ---*/
	display:-ms-flexbox;/*--- IE10 ---*/
	display: -webkit-flex;/*--- safari（PC）用 ---*/
	display: flex;
	-webkit-box-pack:center;/*--- Androidブラウザ用 ---*/
	-ms-flex-pack:center;/*--- IE10 ---*/
	-webkit-justify-content:center;/*--- safari（PC）用 ---*/
	justify-content:center;
	margin: 2vh auto 1vh;
}
#keyVisual .keyCv li{
	width: 49%;
	margin: 0 0 0 10px;
}
#keyVisual .keyCv li:first-child{
	margin-left: 0;
}
#keyVisual .keyCv .startLink a{
	color: #a3762b;
	text-decoration: underline;
	font-size: 14px;
}
#keyVisual .keyCv .startLink a:after{
	content: " ＞";
}



#contents .category_ttl02 {
	margin-bottom: 30px;
	text-align: center;
	color: #aa8546;
}

#contents .category_ttl02 span {
	display: inline-block;
	padding-bottom: 10px;
	border-bottom: 2px solid #aa8546;
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
}

#contents .category_ttl02.small span {
	font-size: 18px;
}




#loading {
	width: 100%;
	height: 100%;
	margin: 0;
	background: #fff;
	opacity: 1.0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999; /*とりあえず一番前面に出るように*/
}
#loading img {
	width:32px;
	height:32px;
	position:absolute;
	top:50%;
	left:57%;
	margin-top:-32px;
	margin-left:-32px;
}
#topBnrArea{
	padding: 20px 6.25% 0;
}

#topBnrArea li{
	margin-top: 12px;
}

#topBnrArea li:nth-of-type(3) {
	margin-top: 30px;
}

#topBnrArea li:first-child{
	margin-top: 0;
	text-align: center;
	font-weight: bold;
	color: #a3864d;
}


.bnr_lounge{padding:8px 6.25% 0;}
#main_contents {
	padding:0 6.25%;
}


.movie {
	overflow: hidden;
	position: relative;
	width: 100%;
	margin-bottom: 20px;
	&_wrapper {
		overflow: hidden;
		width: getvw(320, 320);
		height: getvw(180, 320);
	}
	.video_object {
		position: absolute;
		top: getvw(-180, 320);
		left: 0;
		width: getvw(320, 320);
		height: getvw(540, 320);
		pointer-events: none;
	}
	.video_thumbnail {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	.cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
}
.loader,
.loader:after {
	border-radius: 50%;
	width: 5em;
	height: 5em;
}
.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -2.5em 0 0 -2.5em;
	font-size: 10px;
	text-indent: -9999em;
	border-top: .3em solid rgba(161, 128, 70, .3);
	border-right: .3em solid rgba(161, 128, 70, .3);
	border-bottom: .3em solid rgba(161, 128, 70, .3);
	border-left: .3em solid #A18046;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.2s infinite linear;
	animation: load8 1.2s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.main_banner {
	box-sizing: border-box;
	position: relative;
	height: getvw(126);
	padding: 0 relative-size(20, 320);
	margin: 0 auto relative-size(20, 320);
	z-index: 0;
	img {
		vertical-align: top;
	}
	&_list {
		width: 100%;
		height: getvw(126);
		&_item {
			float: left;
		}
	}
	.slick-arrow {
		position: absolute;
		top: 0;
		width: relative-size(20, 320);
		height: 100%;
		font-size: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: none;
		border: none;
		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			content: "";
			width: 7px;
			height: 12px;
			margin: -6px 0 0 -4px;
		}
	}
	.slick-prev {
		left: 0;
		&:before {
			background: url("../images/top/ico_slide_prev.png") no-repeat 0 0;
			background-size: contain;
		}
	}
	.slick-next {
		right: 0;
		&:before {
			background: url("../images/top/ico_slide_next.png") no-repeat 0 0;
			background-size: contain;
		}
	}
}

.concierge_banner {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: getvw(124);
	margin-bottom: relative-size(20);
	p {
		position: absolute;
		top: getvw(16, 320);
		left: getvw(19, 320);
		color: #a18046;
		font-size: getvw(13, 320);
		line-height: line-height(13, 10);
		@include letterSpacing(300);
		z-index: 2;
	}
	&_inner {
		position: relative;
		width: 100%;
		height: getvw(124);
		z-index: 1;
	}
	&_image {
		width: 100%;
		height: getvw(348, 640);
	}
	img {
		width: 100%;
		height: 100%;
	}
	.cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #f6f3ef;
		z-index: 10;
	}
}

.pickup_menu {
	width: 100%;
	padding: 40px 0 30px;
	background-color: #f0f0f0;
	li {
		box-sizing: border-box;
		width: relative-size(280, 320);
		margin: 0 auto 10px;
		border-top: #808080 solid 1px;
		border-bottom: #808080 solid 1px;
		&:nth-child(1) {
			a {
				&:before {
					top: 19px;
					left: 25px;
					width: 46px;
					height: 65px;
					background: url("/images/top/ico_pickup_menu01.png") no-repeat 0 0;
					background-size: contain;
				}
			}
		}
		&:nth-child(2) {
			a {
				&:before {
					top: 23px;
					left: 22px;
					width: 51px;
					height: 60px;
					background: url("/images/top/ico_pickup_menu02.png") no-repeat 0 0;
					background-size: contain;
				}
			}
		}
		&:nth-child(3) {
			a {
				&:before {
					top: 29px;
					left: 17px;
					width: 62px;
					height: 48px;
					background: url("/images/top/ico_pickup_menu03.png") no-repeat 0 0;
					background-size: contain;
				}
			}
		}
		&:nth-child(4) {
			a {
				&:before {
					top: 23px;
					left: 25px;
					width: 45px;
					height: 61px;
					background: url("/images/top/ico_pickup_menu04.png") no-repeat 0 0;
					background-size: contain;
				}
			}
		}
	}
	a {
		position: relative;
		display: block;
		padding: 22px 0 22px 92px;
		text-decoration: none;
		&:before {
			position: absolute;
			display: block;
			content: "";
		}
	}
	&_title {
		color: #A18046;
		font-size: 15px;
		line-height: 1;
		@include letterSpacing(250);
	}
	&_description {
		margin-top: 7px;
		color: #231815;
		font-size: 12px;
		line-height: line-height(12, 9);
		@include letterSpacing(100);
	}
}

.ideal_person {
	&_header {
		margin-bottom: 34px;
		text-align: center;
	}
	&_heading {
		position: relative;
		margin-top: 39px;
		font-size: 17px;
		line-height: 1;
		@include letterSpacing(150);
		&:after {
			position: absolute;
			bottom: -21px;
			left: 50%;
			display: block;
			content: "";
			width: 56px;
			height: 1px;
			margin-left: -28px;
			background-color: #a18046;
		}
	}
	&_lead_text {
		margin-top: 40px;
		font-size: 12px;
		line-height: 1;
		@include letterSpacing(250);
	}
	&_list {
		&_item {
			margin: 0 relative-size(20, 320) relative-size(10, 320);
		}
		a {
			box-sizing: border-box;
			display: block;
			padding: 6px;
			border: #808080 solid 1px;
			color: #ffffff;
			text-decoration: none;
		}
	}
	&_image {

	}
	&_text {
		padding: 3px 0 4px;
		background-color: #a18046;
		text-align: center;
		&_kind {
			position: relative;
			font-size: 12px;
			line-height: line-height(12, 22);
			&:after {
				position: absolute;
				bottom: -1px;
				left: 50%;
				display: block;
				content: "";
				width: 22px;
				height: 1px;
				margin-left: -11px;
				background-color: #fff;
			}
		}
		&_title {
			font-size: 12px;
			line-height: line-height(12, 22);
		}
	}
}

.join {
	&_header {
		margin-bottom: 34px;
		text-align: center;
	}
	&_heading {
		position: relative;
		margin-top: 33px;
		font-size: 16px;
		line-height: line-height(16, 11);
		@include letterSpacing(250);
		&:after {
			position: absolute;
			bottom: -13px;
			left: 50%;
			display: block;
			content: "";
			width: 56px;
			height: 1px;
			margin-left: -28px;
			background-color: #a18046;
		}
	}
	&_lead_text {
		margin-top: 33px;
		font-size: 12px;
		line-height: 1;
		@include letterSpacing(250);
	}
	&_list {
		&_item {
			margin: 0 relative-size(20, 320) relative-size(10, 320);
			.image_area {
				width: 100%;
			}
		}
		a {
			box-sizing: border-box;
			display: flex;
			text-decoration: none;
		}
	}
}

.pickup {
	&_header {
		margin-bottom: 34px;
		text-align: center;
	}
	&_heading {
		position: relative;
		margin-top: 36px;
		font-size: 16px;
		line-height: line-height(17, 10);
		@include letterSpacing(150);
		&:after {
			position: absolute;
			bottom: -16px;
			left: 50%;
			display: block;
			content: "";
			width: 56px;
			height: 1px;
			margin-left: -28px;
			background-color: #a18046;
		}
	}
	&_lead_text {
		margin-top: 33px;
		font-size: 14px;
		line-height: 1;
		@include letterSpacing(250);
	}
	&_list {
		&_item {
			margin: 0 relative-size(20, 320) relative-size(10, 320);
		}
		a {
			box-sizing: border-box;
			display: block;
			padding: 6px;
			border: #808080 solid 1px;
			color: #ffffff;
			text-decoration: none;
		}
	}
	&_text {
		padding: 3px 0 4px;
		background-color: #C61F10;
		text-align: center;
		&_catch {
			position: relative;
			font-size: 12px;
			line-height: line-height(12, 22);
			&:after {
				position: absolute;
				bottom: -1px;
				left: 50%;
				display: block;
				content: "";
				width: 22px;
				height: 1px;
				margin-left: -11px;
				background-color: #fff;
			}
		}
		&_title {
			font-size: 12px;
			line-height: line-height(12, 22);
		}
	}
}

.news_box {
	&_header {
		margin-bottom: 34px;
		text-align: center;
	}
	&_heading {
		margin-top: 35px;
		position: relative;
		font-size: 16px;
		line-height: line-height(17, 10);
		@include letterSpacing(150);
		&:after {
			position: absolute;
			bottom: -16px;
			left: 50%;
			display: block;
			content: "";
			width: 56px;
			height: 1px;
			margin-left: -28px;
			background-color: #a18046;
		}
	}
	&_lead_text {
		margin-top: 34px;
		font-size: 14px;
		line-height: 1;
		@include letterSpacing(250);
	}
	&_list {
		margin: 0 20px;
		border-bottom: #808080 solid 1px;
	}
	dl {
		padding: 10px 0 9px;
		border-top: #808080 solid 1px;
		dt {
			margin: 0 0 2px;
			padding: 0;
			font-size: 12px;
			line-height: line-height(12, 9);
			background: none;
			@include letterSpacing(100);
		}
		dd {
			margin: 0;
			padding: 0;
			font-size: 12px;
			line-height: line-height(12, 8);
			@include letterSpacing(100);
		}
	}
}
.news_list_link {
	width: 106px;
	margin: 20px auto 40px;
	a {
		box-sizing: border-box;
		display: block;
		width: 100%;
		padding: 11px 0;
		border: #A18046 solid 1px;
		color: #a18046;
		font-size: 12px;
		line-height: 1;
		text-align: center;
		@include letterSpacing(250);
		text-decoration: none;
	}
}









#other_contents {
	padding:0 0 20px;
}
#other_contents .other_menu {
}
#other_contents .other_menu li {
	float:left;
	margin-bottom:3px;
	width:49.531%;
}
#other_contents .other_menu li:nth-child(2n+0) {
	float:right;
}
#other_contents .other_menu li.last {
	width:100%;
}
#other_contents .banner_menu {
	background:#000000;
	margin-bottom:3px;
	padding:0 6.25% 25px;
}
#other_contents .banner_menu li {
	width:100%;
	line-height:1.3em;
	padding: 0.6em 0;
	border-bottom:1px solid #ffffff;
	display: -webkit-flex; /* Safari */
	display: flex;
	-webkit-justify-content: space-between; /* Safari */
	justify-content:         space-between;
	-webkit-align-items: center; /* Safari */
	align-items:         center;
}
#other_contents .banner_menu li:before {
	content: '';
	width: 43px;
	height: 50px;
	background-size: contain;
	vertical-align: middle;
}
#other_contents .banner_menu li.bnr01:before {
	background-image: url(../images/top/banner/bnr_icon01.png);
}
#other_contents .banner_menu li.bnr02:before {
	background-image: url(../images/top/banner/bnr_icon02.png);
}
#other_contents .banner_menu li.bnr03:before {
	background-image: url(../images/top/banner/bnr_icon03.png);
}
#other_contents .banner_menu li.bnr04:before {
	background-image: url(../images/top/banner/bnr_icon04.png);
}
#other_contents .banner_menu li.bnr05:before {
	background-image: url(../images/top/banner/bnr_icon05.png);
}
#other_contents .banner_menu li.bnr06:before {
	background-image: url(../images/top/banner/bnr_icon06.png);
}
#other_contents .banner_menu li.bnr07:before {
	background-image: url(../images/top/banner/bnr_icon07.png);
}
#other_contents .banner_menu li.bnr08:before {
	background-image: url(../images/top/banner/bnr_icon08.png);
}
#other_contents .banner_menu li.bnr09:before {
	background-image: url(../images/top/banner/bnr_icon09.png);
}
#other_contents .banner_menu li.bnr10:before {
	background-image: url(../images/top/banner/bnr_icon10.png);
}
#other_contents .banner_menu li.bnr12:before {
	background-image: url(../images/top/banner/bnr_icon12.png);
}
#other_contents .banner_menu li.bnr13:before {
	background-image: url(../images/top/banner/bnr_icon13.png);
}
#other_contents .banner_menu li.bnr14:before {
	background-image: url(../images/top/banner/bnr_icon14.png);
}
#other_contents .banner_menu li.bnr15:before {
	background-image: url(../images/top/banner/bnr_icon15.png);
}

#other_contents .banner_menu li a{
	display: block;
	color: #fff;
	text-decoration: none;
	width: 74%;
	font-size: 100%;
}
#other_contents .banner_menu li:after {
	content: '';/*何も入れない*/
	display: inline-block;/*忘れずに！*/
	width: 23px;/*画像の幅*/
	height: 50px;/*画像の高さ*/
	background-image: url(../images/top/banner/footer_bnr_ar.png);
	background-size: contain;
	vertical-align: middle;
}
#other_contents .official_menu {
	padding:20px 0 25px;
	text-align:center;
	letter-spacing: -.40em;
	clear:both;
}
#other_contents .official_menu li {
	display:inline-block;
	margin-bottom:5px;
	padding:0 5px;
	letter-spacing:normal;
	line-height:100%;
	border-right:1px solid #000000;
}
#other_contents .official_menu li a {
	color:#000000;
	font-size:11px;
	text-decoration:none;
}
#other_contents .link_pc {
	text-align:center;
}
#other_contents .link_pc a {
	color:#000000;
	font-size:11px;
}

#footer.top_option {
	border-top:0;
}
#other_contents .link_modal {
	padding:0 0 20px;
	text-align:center;
}
#other_contents .link_modal a {
	color:#000000;
	font-size:14px;
}
#other_contents .serviceInfo{
	padding: 0 6.25% 10px;
}
#other_contents .serviceInfo dl{
	border: none;
}
#other_contents .serviceInfo dt{
	font-size: 12px;
	text-align: center;
}
#other_contents .serviceInfo dd{
	font-size: 11px;
	color: #595757;
	padding-left: 0;
	padding-right: 0;

}
#other_contents .serviceInfo .closeCopy{
	margin-bottom: 15px;
}
#other_contents .serviceInfo .closeCopy dt{
	text-decoration: underline;

}
#other_contents .serviceInfo .openCopy dt{
	border: 1px solid #bbb;
	background-image: none;
}

/* 00 フォーム共通
--------------------------*/

/*--フォームの装飾を初期化--*/
input[type=text],
select,
textarea,
input[type="button"],
input[type="submit"],
input[type="image"] {
	font-size:16px;
	-webkit-border-radius : 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}
/*--//フォームの装飾を初期化--*/

/*--セレクトボックスの背景--*/
select,
select.reqFalse  {
	background-image:url(../images/inquiry/icon_select_arrow.gif);
	background-position:right top;
	background-repeat:no-repeat;
	background-size:auto 100%;
}
/*--//セレクトボックスの背景--*/

dl {
	margin:0;
	clear:both;
	border-top:1px solid #888888;
}
dl.last {
	border-bottom:1px solid #888888;
}
dt {
	background-image:url(../images/inquiry/bg_form.gif);
	background-repeat:repeat;
	padding:10px 6.25%;
	font-size:15px;
}
dt span.icon,
dd .kibou span.icon {
	background:#E1200F;
	display:inline-block;
	margin-right:12px;
	padding:2px 5px;
	color:#ffffff;
	font-size:13px;
	text-align:center;
}
dt span.icon02,
dd .kibou span.icon02 {
	background:#888888;
	display:inline-block;
	margin-right:12px;
	padding:2px 5px;
	color:#ffffff;
	font-size:13px;
	text-align:center;
}
dd {
	padding:10px 6.25%;
	font-size:15px;
}
dd input[type="text"],
dd select {
	padding:10px;
}
dd select.area,
dd select.party {
	width:100%;
}
dd .nameL {
	float:left;
	width:46.607%;
}
dd .nameR {
	float:right;
	width:46.607%;
}
dd .nameL label,
dd .nameR label {
	display:block;
	padding:0 0 8px;
}
dd .kibou label,
dd .kibou label {
	display:inline-block;
	padding:0 0 8px;
}
dd input[type="text"],
dd select.place,
dd textarea {
	padding:10px;
	width:100%;
	box-sizing:border-box;
}
dd input[type="text"].code {
	width:30%;
}
dd input[type="text"].tel {
	width:25%;
}
dd input[type="radio"] {
	display:none;
	/*display:inline-block;
	vertical-align:baseline;*/
}
/**/
#radio{
	height:40px;

}
.radio label,
#radio label{
	display:inline-block;
	position:relative;
	padding:11px 20px 0 44px;

}
.radio label{
	margin-bottom: 20px;
}
.radio label:before, .radio label:after,
#radio label:before, #radio label:after{
	content:'';
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:35px;
	height:35px;
	border-radius:50%;
}
.radio label:before,
#radio label:before{
	background:#fafafa;
	border:1px solid #666666;
}
.radio input:checked + label:after,
#radio input:checked + label:after{
	top:9px;
	left:9px;
	height:18px;
	width:18px;
	background:#A3762B;
}

/**/
dd select.birth_y {
	width:88.857%;
}
dd select.birth_m,
dd select.birth_d {
	width:38.285%;
}
dd select.kibou_t,
dd select.kibou_m {
	width:46.428%;
}
dd select.kibou_t {
	margin-right:4.114%;
}
dd .text_red {
	color:#e1200f;
	font-size:11px;
}
.text_area {
	padding:18px 6.25%;
	font-size:14px;
	line-height:135%;
}
.text_area .text_security p {
	font-size:12px;
}

table.agree_box,
table.agree_box th,
table.agree_box td {
	border:0;
	border-collapse:collapse;
}
table.agree_box {
	margin:0 0 20px;
	width:100%;
}
table.agree_box th,
table.agree_box td {
	margin:0;
	padding:0;
}
table.agree_box th.agree {
	background:#BE9F6A;
	width:38.75%;
	text-align:center;
	vertical-align:middle;
	border-right:1px solid #ffffff;
}
table.agree_box th.agree.reqFalse {
	background:#ff8686;
}
table.agree_box th.agree label {
	font-size:13px;
}
table.agree_box th.agree input[type="checkbox"] {
	display:inline-block;
	vertical-align:baseline;
}
table.agree_box td.check {
	background:#000000;
	margin:0;
	padding:0;
	width:60.535%;
	line-height:0;
}
table.agree_box td.check input[type="image"]  {
	width:100%;
}
.text_area span {
	color:#e1200f;
}
.text_area .ind {
	margin:0 0 0 7px;
	text-indent:-7px;
}
.text_area .ind2 {
	margin-left:12px;
	font-size:11px;
	text-indent:-12px;
}
.btn_area {
	margin:0 auto 20px;
}
.btn_area li {
	width:49.464%;
}
.btn_area li.btn_back {
	float:left;
}
.btn_area li.btn_send {
	float:right;
}
.btn_area li.btn_send input[type="image"],
.btn_area li.btn_back input[type="image"]  {
	width:100%;
}
.back_top {
	font-size:14px;
	text-align:center;
}
.page_top {
	margin:0 auto 30px;
	width:11.875%;
}

.box_campaign {
	padding:0 6.25% 20px;
	border-bottom:1px solid #cccccc;
}
.box_campaign .campaign_copy {
	margin-bottom:18px;
	font-size:14px;
	line-height:135%;
}
.box_campaign .campaign_note {
	margin-bottom:15px;
}
.box_campaign .campaign_note li {
	margin:0 0 0 11px;
	font-size:11px;
	line-height:145%;
	text-indent:-11px;
}
.box_campaign .campaign_note li span {
	color:#d8000f;
	font-size:11px;
}
.box_campaign .campaign_heijitsu {
	font-weight:700;
	text-align:center;
	line-height:150%;
}
.box_campaign .campaign_heijitsu p {
	font-size:16px;
}
.box_campaign .campaign_heijitsu span {
	color:#e1200f;
	font-size:16px;
}
.box_price {
	padding:10px 0 20px;
}
.box_price_header {
	padding:0 6.25%;
}
.box_price_header .price_ttl {
	margin-bottom:20px;
	padding:15px 0;
	color:#a3762b;
	font-size:20px;
	line-height:120%;
	border-bottom:2px solid #A3762B;
	clear:both;
}
.box_price_header .price_copy {
	background-image:url(../images/icon01.gif);
	background-repeat:no-repeat;
	background-position:left top;
	background-size:4px 17px;
	margin-bottom:20px;
	padding:0 0 0 10px;
	font-size:17px;
	line-height:100%;
}
.box_price_inner {
	background:#F5F5F5;
	padding:16px 6.25%;
}
.box_price_inner p {
	font-size:14px;
	line-height:135%;
}
.box_price_inner .price_course_menu {
	margin-bottom:15px;
	font-size:15px;
	line-height:100%;
}
.box_price_inner dl.price_list {
	margin:0;
	padding:15px 0;
	line-height:120%;
	border-top:1px solid #cccccc;
	clear:both;
}
.box_price_inner dl.price_list.last {
	border-bottom:1px solid #cccccc;
}
.box_price_inner dl.price_list dt {
	background-image:none;
	float:left;
	padding:3px 0 3px 12.142%;
	width:26.714%;
	font-size:14px;
}
.box_price_inner dl.price_list dt.price01 {
	background-image:url(../images/inquiry/request/icon_price01.gif);
	background-repeat:no-repeat;
	background-position:left center;
	background-size:20px;
}
.box_price_inner dl.price_list dt.price02 {
	background-image:url(../images/inquiry/request/icon_price02.gif);
	background-repeat:no-repeat;
	background-position:left center;
	background-size:20px;
}
.box_price_inner dl.price_list dt.price03 {
	background-image:url(../images/inquiry/request/icon_price03.gif);
	background-repeat:no-repeat;
	background-position:left center;
	background-size:20px;
}
.box_price_inner dl.price_list dt.price04 {
	background-image:url(../images/inquiry/request/icon_price04.gif);
	background-repeat:no-repeat;
	background-position:left center;
	background-size:20px;
}
.box_price_inner dl.price_list dd {
	float:right;
	padding:5px 0;
	width:60.214%;
	font-size:17px;
}
.box_price_inner dl.price_list dd span {
	font-size:10px;
}
.sp_tag {
	height:1px;
	overflow:hidden;
}






#enquete{
	content: "";
	clear: both;
	display: block;
}


#enquete_list ul li{
	height:40px;
	margin:5px auto;
	float:left;
	width:50%;
}

/* インターネット広告のみ1列表示 */
#enquete_list ul li:nth-of-type(7){
	width:100%;
}

/* 最後の項目の1段下げ */
#enquete_list ul li:last-of-type{
	clear: both;
}

#enquete_list ul li label{
	display:inline-block;
	position:relative;
	padding:11px 0 0 44px;
}


#enquete_list ul li label:before,
#enquete_list ul li label:after{
	content:'';
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:35px;
	height:35px;
	border-radius:50%;
}


#enquete_list ul li label:before{
	background:#fafafa;
	border:1px solid #666666;
}


#enquete_list ul li input:checked + label:after {
	top: 9px;
	left: 9px;
	height: 18px;
	width: 18px;
	background: #A3762B;
}

/* 01 資料請求
--------------------------*/

#request {
	padding:25px 0;
}
#request.thanks {
	margin-bottom:30px;
	border-bottom:1px solid #cccccc;
}

#request .category_ttl {
	margin-bottom: 30px;
}

#request .category_ttl + .catch {
	margin: 0 auto;
	padding-bottom: 40px;
	width: 60%;
	line-height: 0;
}
#request .category_ttl + .service_box02  {
	width: 94%;
	margin: 0 auto 30px;
}
#request .category_ttl + .service_box02 .catch_txt {

	font-size: 17px;
	margin-bottom: 30px;
	line-height: 2;
}

#request .main_copy {
	margin-bottom:15px;
}
#request .main_visual {
	margin:0 auto 40px;
	width:87.5%;
}
#request .form_ttl {
	margin-bottom:20px;
}
#request .form_text {
	padding:20px 6.25%;
	font-size:12px;
	line-height:145%;
}
#request .form_text02 {
	margin:0 6.25% 20px;
	padding:4px 10px;
	font-size:12px;
	line-height:145%;
}
#request .form_text02 {
	font-size:12px;
	border:1px solid #a70100;
}
#request .form_text02 span {
	color: #a70100;
	font-size:12px;
}
#request .thanks_text {
	padding:23px 0 22px;
	text-align:center;
	font-size:16px;
}

/*----結婚相談所の選び方180907---*/

#request .book_ttl{
	margin: 0 auto!important;
	-webkit-margin-before: 0;
	-webkit-margin-after: 0;
	font-size: 0;
}
#request .book_ttl img {
	max-width: 100%;
	height: auto;
}
#request .box_book {
	width: 100%;
	background: url(../images/inquiry/request/tool/box_book_bg.png);
	text-align: center;
	padding: 30px 0 0;
	margin: 0 auto 30px;
	text-align: center;
}
#request .box_book p.read_text02 {
	padding: 0 15px 15px;
	font-size: 16px;
	line-height: 2;
}
#request .box_book p.read_text02 span {
	font-weight: bold;
	font-size: 16px;
	background: url(../images/inquiry/request/tool/book_underline.png) repeat-x bottom;
}
#request .box_book p sup {
	font-size: 12px;
	vertical-align:super;
	line-height: 1;
}
#request .box_book p {
	display: block;
}
#request .box_book figure{
	-webkit-margin-before: 0;
	-webkit-margin-after: 10px;
	-webkit-margin-start: 0;
	-webkit-margin-end: 0;
}
#request .box_book figure img {
	width: 100%;
	height: auto;
	margin: 0 0 10px;
	padding-bottom: 1px;
}
#request .box_book p.read_text03 {
	padding: 0 15px 15px;
	font-size: 16px;
}
#request .box_book p.read_text04 {
	text-align: right;
	font-size: 12px;
	padding-right: 10px;
}
#request ul.catch_book_txt {
	max-width: 100%;
	display: table;
	margin:0 15px 50px;
	list-style-type:none;
	-webkit-margin-before: 0;
	-webkit-margin-after: 20px;
	-webkit-margin-start: 10px;
	-webkit-margin-end: 10px;
	-webkit-padding-start: 0;
}
#request ul.catch_book_txt li:nth-child(1){
	width: 60%;
	text-align: left;
	vertical-align: top;
	display:table-cell;
	line-height: 2;
	background: none;
	padding: 0;
	font-size: 17px;
}
#request ul.catch_book_txt li:nth-child(2){
	width: 25%;
	text-align: right;
	display:table-cell;
	padding-left: 5px;
}
#request ul.catch_book_txt li:nth-child(2) img{
	max-width: 90%;
}


#analysis.thanks .box_campaign,
#request.thanks .box_campaign{
	border-bottom: none;
}
#analysis.thanks .merit_sec .price_ttl,
#request.thanks .merit_sec .price_ttl,
#request.thanks .dayservice_sec .price_ttl{
	margin-bottom: 20px;
	padding: 15px 0;
	color: #a3762b;
	font-size: 20px;
	line-height: 120%;
	border-bottom: 2px solid #A3762B;
	clear: both;
}
#analysis.thanks .merit_sec h5,
#request.thanks .merit_sec h5{
	line-height: 1.5;
	font-size: 16px;
	color: #a3762b;
	font-weight: normal;
	margin-bottom: 20px;
}
#analysis.thanks .merit_sec h5 span,
#request.thanks .merit_sec h5 span{
	background-color: #a3762b;
	color: #ffffff;
	padding: 3px 10px;
	font-size: 14px;
	letter-spacing: 0.1em;
	margin-bottom: 10px;
	display: block;

}


/* 02 来店予約
--------------------------*/

#reservation {
	padding: 25px 0 ;
}
#reservation .category_ttl {
	margin-bottom:20px;
}
#reservation .main_copy {
	margin-bottom:0;
}
#reservation .main_visual {
	margin:0 auto 30px;
	width:87.5%;
}
#reservation .form_ttl {
	margin-bottom:20px;
}
#reservation .service_box {
	margin-bottom:40px;
}

#reservation .service_box .catch {
	margin: 0 auto;
	padding-bottom: 40px;
	width: 80%;
}

#reservation .service_box .detail_btn {
	margin-bottom:15px;
	width:36.785%;
}
#reservation .service_box .text_check {
	padding:15px 0 0;
	border-top:1px solid #ffffff;
}

#reservation .category_ttl + .service_box02  {
	width: 94%;
	margin: 0 auto 30px;
}
#reservation .category_ttl + .service_box02 .catch_txt {
	font-size: 17px;
	margin-bottom: 30px;
	line-height: 2;
}
#reservation .form_text {
	padding:20px 6.25%;
	font-size:12px;
	line-height:145%;
}
#reservation .form_text span {
	color:#e1200f;
	font-size:11px;
}
#reservation .form_text span {
	color:#e1200f;
	font-size:11px;
}
#reservation .thanks_text {
	padding:23px 0 22px;
	font-size:14px;
	line-height:150%;
	text-align:center;
}
#reservation .shop_box {
	background:#F5F5F5;
	margin-bottom:20px;
	padding:18px 6.25%;
	text-align:center;
	border-bottom:1px solid #cccccc;
}
#reservation .shop_box_ttl {
	margin-bottom:15px;
	color:#a3762b;
	font-size:16px;
	font-weight:700;
}
#reservation .shop_box_btn {
	background:#A97B3C;
	margin-bottom:15px;
}
#reservation .shop_box_btn a {
	background-image:url(../images/inquiry/reservation/icon01.png);
	background-repeat:no-repeat;
	background-position:right center;
	background-size:21px 16px;
	display:block;
	padding:10px 0;
	color:#ffffff;
	font-size:14px;
	text-align:center;
	text-decoration:none;
}
#reservation .shop_box_btn a span {
	background-image:url(../images/inquiry/reservation/icon_map.png);
	background-repeat:no-repeat;
	background-position:left center;
	background-size:11px 15px;
	display:inline-block;
	padding:0 0 0 16px;
}
#reservation .shop_box_link {
	font-size:14px;
}
#reservation .shop_box_link a {
	color:#a97b3c;
}


/* 03 イベント＆パーティーへのお申し込み
---------------------------------------------*/

#event {
	padding:25px 0;
}
#event .category_ttl {
	margin-bottom:20px;
}
#event .main_copy {
	margin-bottom:12px;
}
#event .sub_copy {
	margin-bottom:15px;
	font-size:12px;
	line-height:140%;
	text-align:center;
}
#event .main_visual {
	margin:0 auto 30px;
	width:87.5%;
}
#event .form_ttl {
	margin-bottom:20px;
}
#event .form_text {
	padding:12px 6.25%;
	font-size:11px;
	line-height:145%;
}
#event .thanks_text {
	padding:23px 6.25% 22px;
	text-align:center;
	font-size:16px;
}
#event .box_mail {
	background:#F5F5F5;
	margin-bottom:30px;
	padding:15px 6.25%;
	font-size:16px;
	line-height:130%;
	text-align:left;
}
#event .box_mail .mail_note {
	margin-bottom:25px;
	padding:25px 0;
	border-bottom:1px solid #CCCCCC;
	border-top:1px solid #CCCCCC;
}
#event .box_mail .mail_note li {
	float:left;
	width:50%;
	font-weight:700;
}
#event .box_mail .mail_text {
	font-size:13px;
	line-height:176%;
	clear:both;
}

/* 04 成婚力診
---------------------------------------------*/
#analysis {
	padding:25px 0;
}
#analysis .category_ttl {
	margin-bottom:20px;
}
#analysis .main_copy {
	margin-bottom:12px;
}
#analysis .sub_copy {
	margin-bottom:15px;
	padding:15px 6.25%;
	font-size:12px;
	line-height:140%;
	text-align:center;
}
#analysis .sample_box {
	background:#F5F5F5;
	margin-bottom:20px;
	padding:15px 6.25%;
	line-height:120%;
	text-align:center;
}
#analysis .main_visual {
	margin:0 auto 8px;
	width:100%;
}
#analysis .btn_samplel {
	margin:0 auto 7px;
	width:42.857%;
}
#analysis dl.attention {
	background:#ffffff;
	margin:0;
	padding:0 3.214%;
	clear:both;
	border:1px solid #E1200F;
}
#analysis dl.attention dt {
	background-image:none;
	color:#e1200f;
	text-align:center;
	cursor:pointer;
}
#analysis dl.attention dt span {
	background-image:url(../images/inquiry/analysis/icon.gif);
	background-repeat:no-repeat;
	background-position:right center;
	background-size:8px 8px;
	display:block;
	font-size:12px;
}
#analysis dl.attention dt.active span {
	background-image:url(../images/inquiry/analysis/icon02.gif);
}
#analysis dl.attention dd {
	display:none;
	padding:12px 0;
	font-size:11px;
	line-height:145%;
	text-align:left;
	border-top:1px solid #E1200F;
}
#analysis dl.attention dd .atte_txt {
	padding:0 0 15px;
}
#analysis dl.attention dd .atte_txt li {
	margin:0 0 5px 12px;
	font-size:11px;
	text-indent:-12px;
}
#analysis dl.attention dd .atte_txt2 {
	padding:0 0 0 20px;
}
#analysis dl.attention dd .atte_txt2 li {
	margin-bottom:5px;
	list-style:disc;
}
#analysis .text_check {
	background:#F7F2EC;
	margin-bottom:20px;
	padding:14px 6.25%;
	color:#a4762b;
	text-align:center;
}
#analysis .text_check p {
	font-size:14px;
	line-height:135%;
}
#analysis .box_faq {
	padding:0 6.25% 30px;
}
#analysis .box_faq dl.analysis {
	margin:0 0 15px;
	clear:both;
	border:0;
}
#analysis .box_faq dl.analysis dt {
	background-image:none;
	float:left;
	padding:0;
	width:12.5%;
	color:#f10000;
	font-size:14px;
}
#analysis .box_faq dl.analysis dd {
	float:right;
	padding:0;
	width:87.5%;
	font-size:14px;
}

#analysis .box_faq .list_faq {
	text-align:center;
	letter-spacing: -.40em;
}
#analysis .box_faq .list_faq li {
	display:inline-block;
	margin:0 6px;
	letter-spacing:normal;
}
#analysis .box_faq .list_faq li input {
	display:none;
}
#analysis .box_faq .list_faq li label {
	background-image:url(../images/inquiry/analysis/num_off.gif);
	background-repeat:no-repeat;
	background-size:45px;
	display:inline-block;
	padding:15px 0 0;
	width:45px;
	height:30px;
	font-size:15px;
	font-family:"ＭＳ Ｐ明朝", "MS PMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
	color:#fff;
	line-height:100%;
	cursor:pointer;
}
#analysis .box_faq .list_faq li input[type="radio"]:checked + label {
	background-image:url(../images/inquiry/analysis/num_on.gif);
	color: #fff;
}
#analysis .box_faq .list_faq.conf {
	padding:0 0 0 30px;
	text-align:left;
}
#analysis .form_ttl {
	margin-bottom:20px;
	padding:30px 6.25% 0;
	font-size:14px;
	border-top:1px solid #888888;
}
#analysis .form_text {
	padding:12px 6.25%;
	font-size:11px;
	line-height:145%;
}
#analysis .thanks_text {
	padding:19px 0;
	font-size:14px;
	text-align:center;
}
#analysis .box_result {
	background:#F5F5F5;
	margin-bottom:18px;
	padding:20px 6.25%;
	color:#a3762b;
	font-size:14px;
	font-weight:700;
	line-height:135%;
	text-align:left;
}

.modal_seikonryoku .bottom {
	padding: 30px 0;
	background: #fff;
	text-align: center;
}
.modal_seikonryoku .bottom p {
	margin: 0 auto;
	width: 170px;
}

/* 05 NEWS & TOPICS
---------------------------------------------*/
#news {
	padding:27px 0 25px;
}
#news .category_ttl {
	margin:0 auto 20px;
	width:64.062%;
}
#news .tab_menu {
	margin:0 auto;
}
#news .tab_menu li {
	float:left;
}
#news .tab_menu li.tab01 {
	width:61.562%;
}
#news .tab_menu li.tab02 {
	width:38.437%;
}
#news .main_copy {
	padding:20px 6.25%;
	font-size:14px;
	line-height:135%;
	border-bottom:1px solid #CCCCCC;
}
#news dl {
	margin:0;
	padding:14px 6.25%;
	border-bottom:1px solid #CCCCCC;
	border-top:0;
	clear:both;
}
#news dt {
	background:none;
	padding:0;
	color:#e1200f;
	font-size:11px;
}
#news dd {
	padding:0;
	font-size:14px;
	line-height:150%;
}
#news .icon_pdf {
	background:#E1200F;
	display:inline-block;
	margin-left:10px;
	padding:3px 4px;
	color:#ffffff;
	font-size:12px;
	font-weight:700;
	line-height:100%;
	text-align:center;
	vertical-align:middle;
}
#news .btn_more {
	margin:0 auto;
	padding:30px 6.25%;
}
#news .menu_year {
	margin:0 auto;
}
#news .menu_year li {
	float:left;
	margin:0 0.3125%;
	width:23.281%;
}

/* 06 PRESSRELEASE
---------------------------------------------*/
#press {
	padding:25px 0 25px;
}
#press .category_ttl {
	margin:0 auto 20px;
	width:45.312%;
}
#press .category_ttl_text {
	margin:0 auto 12px;
	padding:0 0 15px;
	color:#a3762b;
	font-size:20px;
	text-align:center;
	border-bottom:1px solid #CCCCCC;
}
#press .press_release {
	margin:0 auto 15px;
	padding:19px 6.25%;
	font-size:14px;
	line-height:135%;
	border-bottom:1px solid #CCCCCC;
	border-top:1px solid #CCCCCC;
}
#press .press_release p {
	margin-bottom:20px;
	font-size:15px;
	line-height:135%;
}
#press .press_release .press_date {
	margin-bottom:10px;
	color:#e1200f;
	font-size:11px;
	line-height:100%;
	text-align:center;
}
#press .press_release .press_ttl {
	margin-bottom:25px;
	font-size:18px;
	text-align:center;
	line-height:160%;
}
#press .press_release .press_ttl span {
	font-size:15px;
}
#press .press_release .press_copy {
	background-image:url(../images/icon01.gif);
	background-repeat:no-repeat;
	background-position:left top;
	background-size:4px 17px;
	margin-bottom:20px;
	padding:0 0 0 10px;
	font-size:17px;
	line-height:100%;
}
#press .press_release .press_copy.under_line {
	margin-bottom:0;
	padding:0 0 15px 10px;
	border-bottom:1px solid #cccccc;
}
#press dl {
	margin:0;
	padding:14px 0;
	border-bottom:1px solid #CCCCCC;
	border-top:0;
	clear:both;
}
#press dl.last {
	margin-bottom:23px;
}
#press dt {
	background:none;
	margin-bottom:5px;
	padding:0;
	font-size:15px;
}
#press dd {
	padding:0;
	font-size:15px;
}
#press dd span {
	display:block;
	padding:10px 0;
}
#press dd .list_attention {
}
#press dd .list_attention li {
	margin:0 0 8px 14px;
	text-indent:-14px;
}
#press .list_flow {
	padding:0 0 0 15px;
}
#press .list_flow li {
	margin-bottom:12px;
	font-size:14px;
	list-style:decimal;
}
#press .list_relation {
	padding:0 6.25%;
}
#press .list_relation li {
	margin-bottom:14px;
	font-size:14px;
}
#press .partner_agent {
	background:#F5F5F5;
	margin:0 auto 20px;
	padding:20px 6.25%;
	line-height:170%;
	border-top:1px solid #EEEEEE;
}
#press .partner_agent .ttl {
	margin-bottom:10px;
	color:#a3762b;
	font-size:15px;
	font-weight:700;
	line-height:100%;
}
#press .partner_agent p {
	font-size:11px;
}
#press .list_sns {
	padding:12px 0 0;
}
#press .list_sns li {
	float:left;
	width:49.464%;
}
#press .list_sns li:nth-child(2n+0) {
	float:right;
}
#press .text_contact {
	margin:0 auto;
	padding:0 6.25%;
	font-size:11px;
	line-height:145%;
}
#press .text_contact span {
	font-size:11px;
	font-weight:700;
}

/* 07 RECRUIT
---------------------------------------------*/
#recruit {
	padding:25px 0 25px;
}

#recruit .category_ttl {
	margin:0 auto 20px;
	/*width:29.687%;*/
	width:49.3%;
}
#recruit .main_copy {
	margin-bottom:10px;
	font-size:16px;
	line-height:130%;
	text-align:center;
}
#recruit .main_read {
	margin-bottom:18px;
	font-size:10px;
	line-height:140%;
	text-align:center;
}
#recruit .main_menu {
	margin-bottom:25px;
}
#recruit .main_menu li {
	float:left;
	width:33.281%;
}
#recruit .main_menu li:nth-child(2n+0) {
	width:33.437%;
}
#recruit .anchor_menu {
	padding:0 6.25% 11px;
}
#recruit .anchor_menu li {
	float:left;
	margin-bottom:5px;
	width:48.928%;
}
#recruit .anchor_menu li:nth-child(2n+0) {
	float:right;
}
#recruit .anchor_block {
	padding:0 6.25%;
}
#recruit .anchor_ttl {
	padding:15px 0;
	color:#a3762b;
	font-size:20px;
	line-height:100%;
	border-bottom:2px solid #A3762B;
	clear:both;
}
#recruit dl.job {
	margin:0;
	padding:14px 0;
	border-bottom:1px solid #CCCCCC;
	border-top:0;
	clear:both;
}
#recruit dl.job dt {
	background:none;
	margin-bottom:5px;
	padding:0;
	line-height:128%;
}
#recruit dl.job dt a {
	font-size:16px;
	text-decoration:none;
}
#recruit dl.job dt .icon_notice {
	background:#E1200F;
	display:inline-block;
	margin-bottom:7px;
	padding:3px 4px;
	color:#ffffff;
	font-size:12px;
	font-weight:700;
	line-height:100%;
	text-align:center;
	vertical-align:middle;
}
#recruit dl.job dd {
	padding:0;
	color:#888888;
	font-size:14px;
	line-height:140%;
}
#recruit dl.job dd span {
	display:block;
	padding:10px 0;
}
#recruit .box_entry {
	background:#F5F5F5;
	margin:20px 0 10px;
	padding:18px 6.25%;
	font-size:14px;
	line-height:160%;
}
#recruit .box_entry p {
	margin-bottom:12px;
}
#recruit .box_entry_copy {
	margin-bottom:8px;
	font-size:15px;
	line-height:150%;
}
#recruit .list_work {
	padding:10px 0 15px;
}
#recruit .list_work li {
	background-image:url(../images/company/recruit/icon_work.gif);
	background-repeat:no-repeat;
	background-position:left 5px;
	background-size:6px;
	margin-bottom:7px;
	padding:0 0 0 15px;
	font-size:14px;
	line-height:135%;
}
#recruit .box_promote {
	background:#F5F5F5;
	margin-bottom:15px;
	padding:18px 6.25%;
	font-size:14px;
	line-height:180%;
	text-align:left;
}
#recruit .box_promote .promote_copy {
	padding:0 0 10px;
	color:#000000;
}
#recruit .box_promote .promote_copy span {
	font-size:16px;
	line-height:175%;
	border-bottom:1px solid #E1200F;
}
#recruit .box_promote .promote_img {
	margin-bottom:13px;
}
#recruit .box_promote .promote_text {
	margin-bottom:10px;
	font-size:14px;
}
#recruit dl.interview {
	margin:0;
	padding:15px 0;
	text-align:left;
	border-bottom:1px solid #CCCCCC;
	border-top:0;
	clear:both;
}
#recruit dl.interview dt {
	background:none;
	padding:0;
	color:#e1200f;
	font-size:11px;
}
#recruit dl.interview dd {
	padding:0;
	font-size:14px;
	line-height:153%;
}
#recruit .box_delegate {
	margin:34px 0 30px;
	padding:18px 7.142%;
	font-size:14px;
	line-height:150%;
	text-align:left;
	border:2px solid #A3762B;
	clear:both;
}
#recruit .box_delegate_detail {
	padding:0 0 23px;
}
#recruit .box_delegate_ttl {
	margin-bottom:15px;
	padding:0 0 7px;
	color:#a3762b;
	font-size:16px;
	line-height:100%;
	letter-spacing:0.1em;
	border-bottom:1px solid #A3762B;
}
#recruit .box_delegate_copy {
	margin-bottom:10px;
	font-size:16px;
	line-height:130%;
	letter-spacing:0.1em;
}
#recruit .box_delegate_link a {
	color:#a3762b;
}
#recruit .box_delegate_img {
}

/* 08 EVENT
---------------------------------------------*/
#event_top {
	padding:25px 0 25px;
}
#event_top .category_ttl {
	margin:0 auto 20px;
	width:64.062%;
}
#event_top .main_copy {
	margin-bottom:10px;
	font-size:16px;
	line-height:130%;
	text-align:center;
}
#event_top .main_read {
	margin-bottom:18px;
	font-size:10px;
	line-height:140%;
	text-align:center;
}
#event_top .box_flow {
	padding:14px 6.25% 20px;
	font-size:11px;
	border-bottom:1px solid #CCCCCC;
	border-top:1px solid #CCCCCC;
}
#event_top .box_flow {
	padding:14px 6.25% 20px;
	font-size:11px;
	border-bottom:1px solid #CCCCCC;
	border-top:1px solid #CCCCCC;
}
#event_top .box_party {
	padding:10px 6.25% 0;
}
#event_top .box_party p {
	font-size:14px;
	line-height:135%;
}
#event_top .sub_ttl {
	margin-bottom:20px;
	padding:15px 0;
	color:#a3762b;
	font-size:20px;
	line-height:120%;
	border-bottom:2px solid #A3762B;
	clear:both;
}
#event_top .sub_ttl.not_mb {
	margin-bottom:0;
	border-bottom:0;
}
#event_top .event_copy {
	background-image:url(../images/icon01.gif);
	background-repeat:no-repeat;
	background-position:left 3px;
	background-size:4px 17px;
	margin-bottom:12px;
	padding:0 0 0 10px;
	font-size:17px;
	line-height:125%;
}
#event_top .box_pickup {
	margin-bottom:23px;
	padding:0;
	font-size:14px;
	line-height:135%;
	border:1px solid #000000;
}
#event_top .box_pickup .pickup_inner {
	padding:20px 4.642%;
}
#event_top .box_pickup .pickup_inner span {
	color:#e1200f;
}
#event_top .box_pickup .pickup_inner .pickup_ttl {
	margin-bottom:10px;
	font-size:18px;
	font-weight:normal;
	line-height:130%;
}
#event_top .box_pickup .pickup_inner .pickup_link {
	color:#a3762b;
}
#event_top .box_pickup .pickup_inner .pickup_link a {
	color:#a3762b;
	font-size:14px;
}
#event_top dl {
	margin:0 0 18px;
}
#event_top dt {
	background:#A3762B;
	background-image:url(../images/event/icon.png);
	background-repeat:no-repeat;
	background-position:right center;
	background-size:28px 11px;
	padding:20px 10% 10px 5%;
	color:#ffffff;
	font-size:10px;
	position:relative;
	cursor:pointer;
}
#event_top dt span {
	font-size:15px;
}
#event_top dt .icon {
	background:#E1200F;
	display:inline-block;
	margin-right:20px;
	padding:3px 4px;
	color:#ffffff;
	font-size:9px;
	text-align:center;
	line-height:100%;
	vertical-align:middle;
	letter-spacing:0.1em;
	position:absolute;
	left:0;
	top:0;
}
#event_top dd {
	display:none;
	padding:0;
	border:1px solid #B8924A;
}
#event_top dd .box_theme {
	padding:18px 5%;
}
#event_top dd .box_theme p {
	font-size:14px;
	line-height:135%;
}
#event_top dd .box_theme .box_event_ttl {
	margin-bottom:10px;
	font-size:18px;
}
#event_top dd table,
#event_top dd table th,
#event_top dd table td {
	border:0;
	border-collapse:collapse;
}
#event_top dd table {
	margin:0;
	width:100%;
}
#event_top dd table th,
#event_top dd table td {
	padding:10px 4.642%;
}
#event_top dd table th {
	background-image:url(../images/inquiry/bg_form.gif);
	background-repeat:repeat;
	border-top:1px solid #CCCCCC;
}
#event_top dd table td.last {
	border-bottom:1px solid #CCCCCC;
}
#event_top dd table td .note {
	padding:0 0 10px;
}
#event_top dd table td .note li {
	margin:0 0 9px 10px;
	font-size:11px;
	line-height:145%;
	text-indent:-10px;
}
#event_top dd table td .btn {
	margin-bottom:18px;
}
#event_top dd table td .btn_close {
	margin:0 auto;
	width:31.2%;
	cursor:pointer;
}
#event_top p.note {
	font-size:12px;
}



/* GUIDE
---------------------------------------------*/
#guide {
	padding:25px 0 25px;
}
#guide .category_ttl {
	margin:0 auto 20px;
	width:29.687%;
}
#guide .main_copy {
	margin-bottom:10px;
	font-size:16px;
	line-height:130%;
	text-align:center;
}
#guide .main_read {
	margin-bottom:18px;
	font-size:10px;
	line-height:140%;
	text-align:center;
}
#guide .main_menu {
	margin-bottom:25px;
}
#guide .main_menu li {
	float:left;
	width:33.281%;
}
#guide .main_menu li:nth-child(2n+0) {
	width:33.437%;
}
#guide .anchor_block {
	padding:0 6.25%;
	margin:0 0 8%;
}
#guide h2.title {
	margin:6.25% 0 6.25%;
}
#guide h3.title {
	padding:15px 0;
	margin:0 0 18px;
	color:#a3762b;
	font-size:20px;
	line-height:100%;
	border-bottom:2px solid #A3762B;
	clear:both;
}
#guide h4.title {
	background-image:url(../images/icon01.gif);
	background-repeat:no-repeat;
	background-position:left 3px;
	background-size:4px 17px;
	margin-bottom:12px;
	padding:0 0 0 10px;
	font-size:17px;
	line-height:125%;
}
#guide h5.title {
	background-image:url(../images/icon01.gif);
	background-repeat:no-repeat;
	background-position:left 3px;
	background-size:4px 17px;
	margin-bottom:12px;
	padding:0 0 0 10px;
	font-size:17px;
	line-height:125%;
}
#guide h6 {
	font-weight:bold;
	margin:0 0 1%;
}
#guide p {
	margin:0 0 4%;
}
#guide .name {
	text-align:right;
}
#guide .flow {
	background:#f6f1eb;
	padding:5.35%;
	margin:0 0 8%;
}
#guide .flow h4 {
	text-align:center;
	margin:0 0 4%;
}
#guide .flow h4 img {
	width:70%;
	margin:0 auto;
}
#guide .flow li {
	background:url(../images/guide/arrow.gif) no-repeat center bottom / 10% auto;
	padding:0 0 7%;
	margin:0 0 5%;
}
#guide .flow li:last-child {
	margin:0;
	padding:0;
	background:none;
}
#guide .flow dl,
#guide .flow dl dt,
#guide .flow dl dd {
	margin:0;
	padding:0;
	background:none;
	border:0;
}
#guide .flow dl dt {
	width:33%;
	margin:0 3.92% 0 0;
	float:left;
}
#guide ul.list li {
	background:url(../images/guide/icon_dot.gif) no-repeat 0px 43% / 2% auto;
	padding-left:4%;
}
#guide #guide02 section {
	border-bottom:2px solid #ccc;
	padding:0 0 4%;
	margin:0 0 6%;
}


/* COMPANY
---------------------------------------------*/
#company {
	padding:25px 0 25px;
}
#company .category_ttl {
	margin:0 auto 20px;
	width:51.687%;
}
#company .main_copy {
	font-size:16px;
	line-height:130%;
	text-align:center;
	border-top:1px solid #ccc;
	border-bottom:1px solid #ccc;
	margin:0 6.25% 10%;
	padding:6% 0;
}
#company .main_read {
	margin-bottom:18px;
	font-size:10px;
	line-height:140%;
	text-align:center;
}
#company .main_menu {
	margin-bottom:25px;
}
#company .main_menu li {
	float:left;
	width:33.281%;
}
#company .main_menu li:nth-child(2n+0) {
	width:33.437%;
}
#company .anchor_block {
	padding:0 6.25%;
	margin:0 0 8%;
}
#company h2.title {
	margin:6.25% 0 6.25%;
}
#company h3.title {
	padding:15px 0;
	margin:0 0 18px;
	color:#a3762b;
	font-size:20px;
	line-height:100%;
	border-bottom:2px solid #A3762B;
	clear:both;
}
#company h4.title {
	background-image:url(../images/icon01.gif);
	background-repeat:no-repeat;
	background-position:left 3px;
	background-size:4px 17px;
	margin-bottom:12px;
	padding:0 0 0 10px;
	font-size:17px;
	line-height:125%;
}
#company h5.title {
	background-image:url(../images/icon01.gif);
	background-repeat:no-repeat;
	background-position:left 3px;
	background-size:4px 17px;
	margin-bottom:12px;
	padding:0 0 0 10px;
	font-size:17px;
	line-height:125%;
}
#company h6 {
	font-weight:bold;
	margin:0 0 1%;
}
#company p {
	margin:0 0 4%;
}
#company h3 {
	margin:0 0 4%;
}
#company .name {
	text-align:right;
}


/* missionstatement
---------------------------------------------*/
#missionstatement {
	padding:25px 0 25px;
}
#missionstatement .category_ttl {
	margin:0 auto 20px;
	width:21.687%;
}
#missionstatement .anchor_block {
	padding:0 6.25%;
	margin:0 0 8%;
}
#missionstatement p {
	width:92.67%;
	margin:0 auto;
}
#missionstatement .box {
	border-bottom:1px solid #ccc;
	padding:0 0 6%;
	margin:0 0 6%;
}
#missionstatement .box01 {
	border-top:1px solid #ccc;
	padding:6% 0;
}


/* PROFILE
---------------------------------------------*/
#profile {
	padding:25px 0 25px;
}
#profile .category_ttl {
	margin:0 auto 20px;
	width:51.687%;
}
#profile .anchor_block {
	padding:0 6.25%;
	margin:0 0 8%;
}
#profile h3.title {
	padding:15px 0;
	margin:0 0 18px;
	color:#a3762b;
	font-size:20px;
	line-height:100%;
	border-bottom:2px solid #A3762B;
	clear:both;
}
#profile h4.title {
	background-image:url(../images/icon01.gif);
	background-repeat:no-repeat;
	background-position:left 3px;
	background-size:4px 17px;
	margin-bottom:12px;
	padding:0 0 0 10px;
	font-size:17px;
	line-height:125%;
}
#profile h5.title {
	background-image:url(../images/icon01.gif);
	background-repeat:no-repeat;
	background-position:left 3px;
	background-size:4px 17px;
	margin-bottom:12px;
	padding:0 0 0 10px;
	font-size:17px;
	line-height:125%;
	font-weight:700;
}
#profile #anchor01 ul.shop li {
	float:left;
	margin-right:1.5em;
}
#profile #anchor01 .box {
	border:1px solid #ccc;
}
#profile #anchor01 dl {
	border-top:2px solid #ccc;
	margin:0 0 3%;
}
#profile #anchor01 dl dt {
	font-weight:500;
	font-size:18px;
}

#profile #anchor02 section {
	margin:0 0 10%;
}
#profile #anchor02 ul li dl,
#profile #anchor02 ul li dl dt,
#profile #anchor02 ul li dl dd {
	margin:0;
	padding:0;
	background:none;
}

#profile #anchor02 ul li dl dt,
#profile #anchor02 ul li dl dd {
	float:left;
}

#profile #anchor02 ul {
	border-top:1px solid #ccc;
}
#profile #anchor02 ul li dl {
	padding: 6% 0;
	border:0;
	border-bottom:1px solid #ccc;
}
#profile #anchor02 ul li dl dt {
	/*width:22%;*/
	width:35%;
}
#profile #anchor02 ul li dl dd {
	/*width:77%;*/
	width:65%;
}
#profile .anchor_menu {
	padding:0 6.25% 11px;
}
#profile .anchor_menu li {
	float:left;
	margin-bottom:5px;
	width:48.928%;
}
#profile .anchor_menu li:nth-child(2n+0) {
	float:right;
}


/* SITEMAP
---------------------------------------------*/
#sitemap {
	padding:25px 6.25%;
	margin:0 0 8%;
}
#sitemap .category_ttl {
	margin:0 auto 40px;
	width:51.687%;
}
#sitemap h3.title {
	background:url(../images/sitemap/icon_copy.gif) no-repeat 0px 0px;
	margin-bottom:0px;
	padding:0 0 14px 18px;
	font-size:28px;
	line-height:100%;
	letter-spacing:0.05em;
	text-align:left;
	font-weight:600;
	border-bottom:1px solid #ccc;
}
#sitemap h3.title span {
	font-size:18px;
	position:relative;
	top:-4px;
	padding:0 0 0 6px;
}
#sitemap section {
	margin:0 0 60px;
}
#sitemap section ul li a {
	display:block;
	background:url(../images/sitemap/arrow.gif) no-repeat right center;
	padding:6% 0;
	border-bottom:1px solid #ccc;
	text-decoration:none;
}
.areaseoTxt {
	width: 87.5%;
	border: 1px solid #DCDCDC;
	margin: 0 auto 25px;
	text-align: left;
	color: #595757;
	font-size: 13px;
	line-height: 170%;
}

.areaseoTxt p{
	margin: 15px;
}
.areaseoTxt .main_copy {
	font-size: 12px;
	text-align: center;
	background-color: #F5F5F5;
	padding: 3px 0;
	margin: 15px;
}
.areaseoTxt .tar{
	font-size: 11px;}
.areaseoTxt .tar:after{
	content: "総務省統計局「日本の統計2014」";
}
.areaseoTxt .main_copy:after{
	content: "[営業時間] \A平日：13:00～19:00（最終受付時間）\A土・日・祝：10:00～19:00（最終受付時間）";
	white-space: pre;
}


/* 会員専用ページ　モーダル
---------------------------------------------*/
.modal_member {

}
.modal_member .lead {
	padding: 20px;
	background: #fff;
}

.modal_member .lead p {
	margin-bottom: 20px;
}
.modal_member .lead p:last-child {
	margin-bottom: 0;
}
.modal_member .url {
	padding: 20px 0;
	background: url(../images/bg_waffle.gif) 0 0;
}
.modal_member .url p {
	margin: 0 auto;
	width: 80%;
}
.modal_member .bottom {
	padding: 30px 0;
	background: #fff;
	text-align: center;
}
.modal_member .bottom p {
	margin: 0 auto;
	width: 170px;
}
