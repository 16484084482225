@mixin min1280 {
	@media screen and (max-width: 1279px) {
		@content;
	}
}

@mixin max1560 {
	@media screen and (max-width: 1560px) {
		@content;
	}
}
@mixin max1366 {
	@media screen and (max-width: 1366px) {
		@content;
	}
}
@mixin max1280 {
	@media screen and (max-width: 1280px) {
		@content;
	}
}
@mixin max1200 {
	@media screen and (max-width: 1200px) {
		@content;
	}
}
@mixin max1100 {
	@media screen and (max-width: 1100px) {
		@content;
	}
}
