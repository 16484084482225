@import "clearfix";
@import "opacity";
@import "non-space";
@import "list-normalize";
@import "font-size";
@import "line-height";
@import "mediaqueries";
@import "retina-sprite";
@import "relative-size";
@import "tracking";
@import "getvw";
@import "retina";

@mixin rolloverAnimation {
	transition: all 200ms;
}
@mixin anim($sec) {
	transition: all #{$sec}ms;
}
@mixin anim2($sec, $prop) {
	transition: $prop #{$sec}ms;
}