%clearfix {
	&:after {
		content : '';
		display : block;
		clear : both;
		height:0;
	}
}

@mixin clearfix {
	&:after {
		content : '';
		display : block;
		clear : both;
		height:0;
	}
}