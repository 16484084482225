@mixin r-sprite-width($sprite) {
	width: nth($sprite, 5)/2;
}
@mixin r-sprite-height($sprite) {
	height: nth($sprite, 6)/2;
}
@mixin r-sprite-position($sprite) {
	$sprite-offset-x: nth($sprite, 3)/2;
	$sprite-offset-y: nth($sprite, 4)/2;
	background-position: $sprite-offset-x $sprite-offset-y;
}
@mixin r-sprite-size($sprite) {
	background-size: nth($sprite, 7)/2 nth($sprite, 8)/2;
}
@mixin r-sprite($sprite) {
	@include sprite-image($sprite);
	@include r-sprite-position($sprite);
	@include r-sprite-width($sprite);
	@include r-sprite-height($sprite);
	@include r-sprite-size($sprite);
}